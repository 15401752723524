import React, {Component } from 'react'
import { Button } from 'reactstrap'
import RecordsTable from '../RecordsTable/RecordsTable'
import './QuestionsTable.css'

class QuestionsTable extends Component{

    constructor(props){
        super(props)

        //Titles
        this.title_liste_des_champs_du_formulaire = "Champs du formulaire"

        //Message
        this.message_veuillez_saisir_les_champs = "Veuillez saisir les champs !"
        this.message_ajouter_un_champ = "Ajouter un champ ..."
        this.message_ajouter_un_enregistrement = "Ajouter un enregistrement ..."
        this.message_effacer_un_champ = "Effacer..."
        this.message_effacer_un_record = "Effacer cet enregistrement..."

        //Prompt
        this.prompt_no_du_champ = "Nom du champ"
        //Confirm
        this.confirm_supprimer_un_champ = "Supprimer ce champ ?"
        

        //err
        this.err_nom_de_champ_trop_long = "Nom de champ trop long\r\n"
        this.err_nom_de_champ_trop_court = "Nom de champ trop court\r\n"
        this.err_type_de_champ_non_selectionne = "Type de champ non sélectionné\r\n"
    }

    saveAllHandler = e => {
      e.preventDefault();
      alert("Save message sent to records !")
      window.parent.postMessage(
        {
          type: "button-click",
          message: "Hi Patrick !"
        },
        "*"
      );
    }
    

    /**
     * EDIT rendering */
    TableRendering = () => {
      return(
        <div id="survey-table-container" className="col-12"> 
            <table>
              {
                Object.keys(this.props.data).map((key, i) => {
                  
                  let record_data = this.props.data[key]

                  return(
                    <RecordsTable 
                      key={i}
                      record_data={record_data}
                      onResponseTypeSelected={this.props.onResponseTypeSelected}>
                    </RecordsTable>)})
                }
            </table>            
        </div>
      )
    }

    render = () => {
      return (
        <>
          {this.TableRendering()}
        </>);
      }
} 

export default QuestionsTable;
