import React, {Component } from 'react'
import { Button } from 'reactstrap'
import './ConclusionsTable.css'

class ConclusionsTable extends Component{

    constructor(props){
        super(props)

        this.state={conclusion:null}

        this.spiritual_gifts = [
          { name: "Prophétie", value: 0 },
          { name: "Enseignement", value: 0 },
          { name: "Conseil - Relation d'aide", value: 0 },
          { name: "Communication créative", value: 0 },
          { name: "Encouragement", value: 0 },
          { name: "Evangélisation", value: 0 },
          { name: "Hospitalité", value: 0 },
          { name: "Leadership (Direction)", value: 0 },
          { name: "Compassion", value: 0 },
          { name: "Pastorat", value: 0 },
          { name: "Administration", value: 0 },
          { name: "Artisanat", value: 0 },
          { name: "Libéralité", value: 0 },
          { name: "Secours", value: 0 }
        ];

        this.spiritual_gifts.sort(function (a, b) {
          return a.value - b.value;
        });
    }

    componentDidMount = () => {
      this.computeGiftsResults()
      this.spiritual_gifts.sort()
      this.setState({conclusion:this.spiritual_gifts})
    }

    computeGiftsResults = () => {
      for (let iGifts = 0; iGifts <= 13; iGifts++) {
        //console.log("spiritual_gifts:",iGifts,"=>", this.spiritual_gifts[iGifts].name)
        for (let iAnswers = 1; iAnswers <= 85; iAnswers+=14) {
          let user_response_entry = -1 + iGifts + iAnswers
          this.spiritual_gifts[iGifts].value += this.props.data[user_response_entry].user_response
        }
      }
    }

    sortTable = () =>{
      // copy your state.data to a new array and sort it by itemM in ascending order
      // and then map 
      const myData = [].concat(this.props.data)
      .sort((a, b) => a.itemM > b.itemM ? 1 : -1)
      .map((item, i) => 
          <div key={i}> {item.matchID} {item.timeM}{item.description}</div>
      );

      // render your data here...
    }

    /**
     * EDIT rendering */
    TableRendering = () => {
      return(
            <table>
              {
                this.state.conclusion
                .sort((a,b) => a.value > b.value ? -1 : 1)
                .map(
                  SgItem => {
                    return(
                      <tr>
                        <td><b>Don&nbsp;:&nbsp;</b>{SgItem.name}</td>
                        <td><b>Total&nbsp;:&nbsp;</b>{SgItem.value}</td>
                      </tr>)
                  }
                  )
                }
            </table>            
      )
    }

    render = () => {
      return (
        <>
          {this.state.conclusion && this.TableRendering()}
        </>);
      }
} 

export default ConclusionsTable;
