/*
ResponsesTypesCombo Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";
import './ResponsesTypesCombo.css';

class ResponsesTypesCombo extends Component{
    constructor(props){
        super(props);
        this.state = {types:[], selected_response_value:null, open:false}

        this.msg_choisir_un_type_de_reponse = "Choisir une réponse ..."
    }

    componentDidMount = () => {
        this.fetchresponses();
    }

    fetchresponses = async () => {
        const localURL = 'http://localhost:4000/responses-types'
        const remoteURL = `https://skillsurvey-service.neuronal.bible/responses-types`
        const serviceURL = (["localhost", "127.0.0.1"].includes(window.location.hostname))?localURL:remoteURL
        const fetchresult = await axios.get(serviceURL);
        this.setState({types : fetchresult.data.items}, () => {this.setState({selected_response_value:this.props.selected_response_value});});
    };

    findTitleFromValue = (idtypes_searched) => {//Look for types object given a idtypes
        if (idtypes_searched == -1)
            return(this.msg_choisir_un_type_de_reponse) //No active selection

        let ii = 0;
        while (ii < this.state.types.length){
            if (this.state.types[ii].idresponses_types == idtypes_searched) return `${this.state.types[ii].idresponses_types} - ${this.state.types[ii].response_title}`;
            ii++;
        }

        //console.error(`Unexpected dropdown error - with idtypes_searched: ${idtypes_searched} !`)
        return null;
    }
    
    typesDropDownRenderer = () => {
        return(
            <div>
                <ButtonDropdown  isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle className={(this.props.selected_response_value >= 0) ? "bg-success":"bg-primary"} caret>
                        {this.findTitleFromValue(this.props.selected_response_value)}
                    </DropdownToggle>
                        
                    <DropdownMenu>
                        {
                            this.state.types.map(
                                typesObjectItem => {
                                    return (
                                        <DropdownItem key={parseInt(typesObjectItem.idresponses_types, 10)} 
                                            onClick = {() => {
                                                                if (this.props.onClick) this.props.onClick(typesObjectItem.idresponses_types, this.props.item_id); 
                                                                else alert("Callback is not yet defined");}}>
                                            {typesObjectItem.idresponses_types} - {typesObjectItem.response_title}
                                        </DropdownItem>
                                    )
                                }
                            )
                        }
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>{this.typesDropDownRenderer()}</div>
        )
    }
}

export default ResponsesTypesCombo;