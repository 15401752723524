import React, {Component} from 'react'
import ResponsesTypesCombo from "../ResponsesTypesCombo/ResponsesTypesCombo"
class RecordsTable extends Component{
    constructor(props){
        super(props)
    }

    componentDidMount = () => {
        /*
        window.addEventListener("message", (event) => {
            // Do we trust the sender of this message?  (might be
            // different from what we originally opened, for example).
            alert(`Save Message reiceived form record ${this.props.key}`)
            if (event.origin !== "http://example.com")
              return;
          
            // event.source is popup
            // event.data is "hi there yourself!  the secret response is: rheeeeet!"
          }, false);  */
    }

    render = () => {
        return(
            <tr key={this.props.record_data.key_id}>
                <td>
                    <b>{this.props.record_data.key_id}</b>
                </td>
                <td>
                    <ResponsesTypesCombo 
                        selected_response_value={this.props.record_data.user_response} 
                        item_id={this.props.record_data.key_id} 
                        onClick={this.props.onResponseTypeSelected}/>
                </td>
                <td>
                    <div>{this.props.record_data.text}</div>
                </td>
            </tr>
        )
    }
}

export default RecordsTable;