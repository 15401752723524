/*
QuestionsTable Component
*/

import React, { Component} from "react";
import {Button} from "reactstrap";
import QuestionsTable from "../QuestionsTable/QuestionsTable";
import ConclusionsTable from "../ConclusionsTable/ConclusionsTable";

class FormSurvey extends Component {
    constructor(props){
        super(props)

        this.state={form_name:"Todo Templates", fields_are_valid:true, form_data:[], debug:false, can_edit_form:true}

        //message
        this.message_verifier_connexion_wifi = "Verifiez votre connexion wifi";
    }

    componentDidMount = () => {
        this.loadHandler()
        this.onResponseTypeSelected = this.onResponseTypeSelected.bind(this)
    }

    componentWillUnmount = () => {
        //alert("componentWillUnmount")
    }

    conclusionEnabled = () => {
        let ii = 0;
        while (ii < this.state.form_data.length){
            if (this.state.form_data[ii].user_response == -1) return false;
            ii++;
        } 
        return true
    }

    conclusionHandler = async event => {
        if (event) event.preventDefault();

        this.setState({can_edit_form:false})
    }


    loadHandler = async event => {
        if (event)
            event.preventDefault();

        //Call micro-service
        let service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                                ?'http://localhost:4000/questions-load'
                                :`https://skillsurvey-service.neuronal.bible/questions-load`;

        var serviceResult = null
        try{
            await fetch(
                    `${service_db_url}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                        method: "GET",}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("client_add_requetes_log => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //Form sucessfully loaded
                        this.setState({form_data:serviceResult.items}, ()=>{
                            if (this.state.debug == true){
                                let debug_form_data = this.state.form_data
                                let ii = 0;
                                while (ii < debug_form_data.length-0){
                                    debug_form_data[ii].user_response = 0;
                                    ii++;
                                } 
                                //Overwrite state
                                this.setState({form_data:debug_form_data},()=>{console.log("state_debug:", this.state);})
                            }
                        })
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`${this.message_verifier_connexion_wifi} : ${err}`);
            return;
        }
    }  

    onResponseTypeSelected = (response_value, item_id) =>{
        let next_form_data = this.state.form_data
        next_form_data[parseInt(item_id, 10) - 1].user_response = parseInt(response_value, 10)
        this.setState({form_data:next_form_data})
    }

    render = () =>{
        return(
            <>
            <div className="container">
                <h1>Dons spirituels</h1>
                <h2>Investigation à partir du caractère</h2>
    
                {this.state.can_edit_form &&
                <>
                    <div className="fluid-container">
                        <div className="align-left">
                            <p>Comment ces affirmations s’appliquent-elles à vous ? Dans quelle mesure reflètent-elles ce que vous êtes ? Répondez à chaque proposition en indiquant le degré de validité vous concernant...</p>
                            {/*
                            <ul>
                                <li>Toujours vrai</li>
                                <li>Souvent vrai</li>
                                <li>Plus ou moins vrai</li>
                                <li>Jamais vrai</li>
                            </ul>*/}
                            <i>Il est important que vous répondiez selon ce que vous êtes réellement et non pas selon ce que vous aimeriez être ou bien ce que vous pensez que vous devriez être ! Il n’y a pas de réponses bonnes ou mauvaises...</i>
                        </div>
                    </div>
    
                
                    <QuestionsTable 
                    onResponseTypeSelected={this.onResponseTypeSelected}
                    validFlag={this.state.fields_are_valid}
                    data={this.state.form_data}/>
                    <br/>
                    <div className="container">
                        <div className="row">
                            <p>
                                Une fois que vous aurez répondu à toutes les questions, le bouton ci-dessous deviendra bleu afin que vous puissiez consulter la conclusion.
                            </p>
                            <Button className={this.conclusionEnabled()?"bg-success":""} disabled={!this.conclusionEnabled()} onClick={this.conclusionHandler}>Consulter la conclusion...</Button>
                        </div>
                    </div>
                </>
                }
    
                {!this.state.can_edit_form &&
                <>
                    <br/>
                    <h3>Conclusion</h3>
                    <p>Merci d'avoir répondu patiemment à toutes les questions. Découvrez dès maintenant les dons qui vous ressemblent<br/>
                    Ils sont triés d'après les aptitudes qui ont été décelées à travers vos réponses au questionnaire.
                    Ainsi vos dons les plus éloquents , vous concernant, se trouvent en tête de la liste ci-dessous.
                    </p>
                    <ConclusionsTable data={this.state.form_data}/>  
                    <br/>
                    <strong>Que Dieu bénisse votre <i>[nouveau]</i> ministère !</strong>
                </>}
    
            </div>
            <div className="footer container-fluid">
                    <i>Réalisation de Patrick Mabialah</i><br/>
                    <i>Inspiré de : Equiping who are willing to serve</i><br/>
                    <i>&copy; 1989 - Bruce Bugbee</i>
            </div>
            </>
                
            
        )}
}

export default FormSurvey;