import React, { useEffect, useState } from 'react';
import './App.css';
import FormSurvey from './Components/FormSurvey/FormSurvey';

function App() {
  /* Prevent refresh */
  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeRefreshHandler);
    return () => {
      window.removeEventListener("beforeunload", onBeforeRefreshHandler);
    };
  }, []);

  const onBeforeRefreshHandler = (e) => {
    e.preventDefault();
    e.returnValue = false;
  };

  return (
    <div className="App">
      <FormSurvey/>
    </div>
  );
}

export default App;
